<template>
  <div>
    <div class="flex justify-start">
      <div class="w-2/3">
        <p class="text-xs text-black">Your Devices:</p>
        <select v-model="selectedDevice" data-cy="all-jobs-device">
          <option
            v-for="device in this.$store.state.devices"
            v-bind:key="device.deviceId"
            v-bind:value="device"
            >{{ device.deviceId }} - {{ device.deviceType }}</option
          >
        </select>
      </div>
      <div>
        <button @click="getJobs" class="my-6" data-cy="find-jobs">
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Find Jobs</span>
        </button>
      </div>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
    <table v-if="allJobs.length" data-cy="jobs-table" class="text-center">
      <th class="font-black">Status</th>
      <th class="font-black">Execution Number</th>
      <th class="font-black">Last Updated At</th>
      <th class="font-black">Queued At</th>
      <th class="font-black">Job Id</th>
      <th class="font-black">Description</th>
      <th class="font-black">Document Source</th>
      <th class="font-black">Actions</th>
      <tr v-for="job in allJobs" v-bind:key="job" class="border py-4">
        <td class="p-4">{{ job.jobExectionSummary.status }}</td>
        <td>{{ job.jobExectionSummary.executionNumber }}</td>
        <td>
          <TwoDates :date="job.jobExectionSummary.lastUpdatedAt" />
        </td>
        <td>
          <TwoDates :date="job.jobExectionSummary.queuedAt" />
        </td>
        <td>{{ job.jobExectionSummary.jobId }}</td>
        <td>{{ job.jobExectionSummary.description }}</td>
        <td>{{ formatLink(job.jobExectionSummary.document) }}{{expandLink ? '' : '...'}}<span class="cursor-pointer text-blue-500" @click="expandLink = !expandLink">{{expandLink ? '[-]' : '[+]'}}</span></td>
        <td>
            <CancelJob :job="job" @refresh="getJobs()" v-if="job.jobExectionSummary.status !==`CANCELED`" />
        </td>
      </tr>
    </table>

    <p v-else>No jobs available</p>
  </div>
</template>

<script>
import TwoDates from '@/components/UI/TwoDates.vue';
import CancelJob from '@/components/forms/CancelJob.vue';

export default {
  data: function () {
    return {
      selectedDevice: {},
      formError: '',
      expandLink: false,
      allJobs: []
    };
  },
  components: {
    TwoDates,
    CancelJob
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    formatLink (documentSource) {
      if (this.expandLink) {
        return documentSource;
      } else {
        return documentSource.slice(0, 40);
      }
    },
    async getJobs () {
      this.$store
        .dispatch('getJobsQuery', {
          deviceId: this.selectedDevice.deviceId,
          deviceType: this.selectedDevice.deviceType
        })
        .then(res => {
          if (res.data) {
            this.allJobs = res.data.allJobs.executionSummaries;
            this.deviceId = '';
            this.deviceType = '';
            this.formError = '';
            if (res.errors) {
              this.formError = res.errors[0].message;
            }
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  }
};
</script>
