<template>
  <div>
    <button @click="toggleModal">
      Delete
    </button>
    <SimpleModal v-if="cancelModal" @close="cancelModal = false">
      <div class="text-center">
        <p class="mb-4">Are you sure you want to delete this job?</p>
        <button
          class="text-center"
          :disabled="loading"
          @click="cancelJob"
        >
          <span v-if="loading">Deleting job...</span>
          <span v-else>Do It</span>
        </button>
        <p class="error" v-if="formError">{{ formError }}</p>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data () {
    return {
      cancelModal: false,
      formError: '',
      loading: false
    };
  },
  props: {
    job: Object
  },
  components: {
    SimpleModal
  },
  methods: {
    toggleModal () {
      this.cancelModal = !this.cancelModal;
      this.formError = '';
    },
    reset () {
      setTimeout(() => {
        this.formError = '';
        this.$emit('refresh');
        this.cancelModal = false;
        this.loading = false;
      }, 2000);
    },
    async cancelJob () {
      this.loading = true;
      this.$store
        .dispatch('cancelJobsMutation', {
          jobId: this.job.jobExectionSummary.jobId,
          comment: 'Deleting Job'
        })
        .then((res) => {
          if (res.errors) {
            this.formError = res.errors[0].message;
            this.loading = false;
          } else {
            this.reset();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }
};
</script>
