<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">
      Advanced Device Operations
    </h1>
    <div class="tabs-component" data-cy="tabs-component">
      <tabs>
        <tab id="create-job" name="Create Job">
          <CreateJob />
        </tab>
        <tab id="all-jobs" name="All Jobs" data-cy="all-jobs-tab">
          <AllJobs />
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import CreateJob from '@/components/forms/CreateJob.vue';
import AllJobs from '@/components/forms/AllJobs.vue';
export default {
  components: {
    CreateJob,
    AllJobs
  }
};
</script>

<style lang="scss">
.tabs-component-tabs {
  border: 0;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  margin-bottom: -1px;
}

.tabs-component-tab {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 3px 3px 0 0;
  margin-right: 0.5em;
  transition: transform 0.3s ease;
}

.tabs-component-tab.is-active {
  border-bottom: solid 1px #fff;
  z-index: 2;
}

.tabs-component-tab-a {
  display: flex;
  padding: 0.75em 1em;
}

.tabs-component-panels {
  border-top-left-radius: 0;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 0 6px 6px 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 2em 2em;
}
</style>
