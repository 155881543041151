<template>
  <div>
    <div class="flex justify-start flex-wrap" @click="clientSideErrors = []">
      <div class="w-1/2">
        <p class="text-xs text-black">Your Devices:</p>
        <select data-cy="create-job-device" v-model="selectedDevice" required>
          <option
            v-for="device in this.$store.state.devices"
            v-bind:key="device.deviceId"
            v-bind:value="device"
            >{{ device.deviceId }} - {{ device.deviceType }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="File Name"
          placeholder="fileName"
          v-model="fileName"
          data-cy="fileName"
          type="text"
          required
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Version"
          placeholder="version"
          v-model="version"
          data-cy="version"
          type="text"
          required
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="File Size"
          placeholder="fileSize"
          v-model="fileSize"
          data-cy="fileSize"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="md5"
          placeholder="md5"
          v-model="md5"
          data-cy="md5"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Target"
          placeholder="target"
          v-model="target"
          data-cy="target"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Force</p>
        <select v-model="force">
          <option
            v-bind:key="type.name"
            v-for="type in forceOptions"
            v-bind:value="type"
            >{{ type }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Operation</p>
        <select v-model="operation" data-cy="operation" required>
          <option
            v-bind:key="type.name"
            v-for="type in $store.getters.taskTypes"
            v-bind:value="type.name"
            >{{ type.name }}</option
          >
        </select>
      </div>
    </div>
    <button
      @click="createJob"
      :disabled="loading"
      class="my-6"
      data-cy="create-job"
    >
      <span v-if="loading">{{ loadingMessage }}</span>
      <span v-else>Create Job</span>
    </button>
    <ul v-if="clientSideErrors">
      <li class="error" v-for="error in clientSideErrors" :key="error">
        {{ error }}
      </li>
    </ul>
    <p class="error" data-cy="errorMessage" v-if="formError">{{ formError }}</p>
    <p
      class="success text-green-500 italic"
      v-if="success"
      data-cy="successMessage"
    >
      Job Created Successfully!
      <a
        href="/advanced#all-jobs"
        @click="clicked"
        data-cy="see-all-jobs"
        >See All Jobs</a
      >
    </p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      selectedDevice: {},
      fileName: null,
      version: null,
      fileSize: null,
      md5: null,
      target: null,
      force: null,
      forceOptions: ['True', 'False'],
      operation: null,
      clientSideErrors: [],
      formError: '',
      success: false
    };
  },
  created () {
    !this.$store.getters.taskTypes.length &&
      this.$store.dispatch('updateTaskTypes');
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    clicked () {
      this.success = false;
    },
    async createJob () {
      if (
        this.selectedDevice &&
        this.operation &&
        this.fileName &&
        this.version
      ) {
        this.$store
          .dispatch('createJobMutation', {
            deviceId: this.selectedDevice.deviceId,
            deviceType: this.selectedDevice.deviceType,
            fileName: this.fileName,
            fileSize: this.fileSize,
            md5: this.md5,
            target: this.target,
            version: this.version,
            force: this.force,
            operation: this.operation
          })
          .then(res => {
            if (res.errors) {
              this.formError = res.errors[0].message;
            } else {
              this.success = true;
              this.selectedDevice = {};
              this.fileName = '';
              this.fileSize = '';
              this.md5 = '';
              this.target = '';
              this.version = '';
              this.force = '';
              this.operation = '';
              this.formError = '';
              this.clientSideErrors = [];
            }
          })
          .catch(() => {
            this.formError = 'Sorry - There was an error. Please try again.';
          });
      } else {
        this.clientSideErrors = [];
        if (!this.selectedDevice.deviceId) {
          this.clientSideErrors.push('Please select device');
        }
        if (!this.operation) {
          this.clientSideErrors.push('Operation is a required field');
        }
        if (!this.fileName) {
          this.clientSideErrors.push('File name is a required field');
        }
        if (!this.version) {
          this.clientSideErrors.push('Version is a required field');
        }
      }
    }
  }
};
</script>
